<template>

  <div class="flex flex-col w-full md:w-auto">
    <textarea
      ref="labelRef"
      class="font-bold rounded-lg overflow-hidden text-sm uppercase outline-none w-full md:w-300px mt-2 p-1 resize-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-dark"
      :style="{ color: accentTextColor }"
      rows='1'
      data-min-rows='1'
      data-height-step='20'
      placeholder="Bill To Label"
      :value="toLabel"
      @input="updateValue('toLabel', $event.target, 20)"
    >
    </textarea>

    <textarea
      ref="nameRef"
      class="overflow-hidden text-lg rounded-lg outline-none w-full md:w-300px p-1 mt-1 resize-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-dark"
      rows='1'
      data-min-rows='1'
      data-height-step='28'
      :value="toName"
      @input="updateValue('toName', $event.target, 28)"
      placeholder="Bill To Name"
    >
    </textarea>

    <textarea
      ref="addressRef"
      class="rounded-lg overflow-hidden text-gray-400 outline-none w-full md:w-300px mt-1 p-1 resize-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-dark"
      rows='2'
      data-min-rows='2'
      data-height-step='24'
      :value="toAddress"
      @input="updateValue('toAddress', $event.target, 24)"
      placeholder="Bill To Address"
    >
    </textarea>
  </div>

</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';

  export default {
    props: [
      'accentTextColor',
      'toLabel',
      'toName',
      'toAddress'
    ],
    emits: [
      'update:toLabel',
      'update:toName',
      'update:toAddress'
    ],
    mounted() {
      this.$nextTick(function() {
        this.autoResizeMX(this.$refs.labelRef,20);
        this.autoResizeMX(this.$refs.nameRef,28);
        this.autoResizeMX(this.$refs.addressRef,24);
      });
    },
    data() {
      return {

      }
    },
    methods: {
      ...mapMutations([

      ]),
      updateValue(prop,target,heightStep) {
        this.autoResizeMX(target,heightStep);
        this.$emit('update:'+prop,target.value);
      }
    },
    computed: {
      ...mapGetters([

      ])
    }
  }
</script>
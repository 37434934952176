<template>

  <div class="flex flex-col w-full mt-4 md:mt-0 md:w-auto md:ml-12">
    <textarea
      ref="labelRef"
      class="rounded-lg font-bold overflow-hidden text-sm uppercase outline-none w-full md:w-300px mt-2 p-1 resize-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-dark"
      :style="{ color: accentTextColor }"
      rows='1'
      data-min-rows='1'
      data-height-step='20'
      placeholder="Ship To Label"
      :value="shipToLabel"
      @input="updateValue('shipToLabel', $event.target, 20)"
    >
    </textarea>

    <textarea
      ref="nameRef"
      class="overflow-hidden text-lg rounded-lg outline-none w-full md:w-300px p-1 mt-1 resize-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-dark"
      rows='1'
      data-min-rows='1'
      data-height-step='28'
      :value="shipToName"
      @input="updateValue('shipToName', $event.target, 28)"
      placeholder="Ship To Name"
    >
    </textarea>

    <textarea
      ref="addressRef"
      class="rounded-lg overflow-hidden text-gray-400 outline-none w-full md:w-300px mt-1 p-1 resize-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-dark"
      rows='2'
      data-min-rows='2'
      data-height-step='24'
      :value="shipToAddress"
      @input="updateValue('shipToAddress', $event.target, 24)"
      placeholder="Ship To Address"
    >
    </textarea>

  </div>

</template>


<script>
  import { mapGetters, mapMutations } from 'vuex';

  export default {
    props: [
      'accentTextColor',
      'shipToLabel',
      'shipToName',
      'shipToAddress'
    ],
    emits: [
      'update:shipToLabel',
      'update:shipToName',
      'update:shipToAddress'
    ],
    mounted() {
      this.$nextTick(function() {
        this.autoResizeMX(this.$refs.labelRef,20);
        this.autoResizeMX(this.$refs.nameRef,28);
        this.autoResizeMX(this.$refs.addressRef,24);
      });
    },
    data() {
      return {

      }
    },
    methods: {
      ...mapMutations([

      ]),
      updateValue(prop,target,heightStep) {
        this.autoResizeMX(target,heightStep);
        this.$emit('update:'+prop,target.value);
      }
    },
    computed: {
      ...mapGetters([

      ]),
    }
  }
</script>
<template>

  <div class="flex flex-col items-start mt-8 md:mt-0 md:items-end">
    <input
      class="text-left md:text-right rounded-lg text-4xl font-bold uppercase w-full md:w-300px p-2 outline-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-dark"
      type="text"
      :value="title"
      @input="updateValue('title', $event.target.value)"
    />

    <input
      class="mt-1 text-left md:text-right text-gray-400 rounded-lg uppercase w-full md:w-300px p-2 outline-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-dark"
      type="text"
      :value="serial"
      @input="updateValue('serial', $event.target.value)"
      @focus="removeHashtag"
      @blur="addHashtag"
    />
  </div>

</template>


<script>
  import { mapGetters, mapMutations } from 'vuex';

  export default {
    props: ['title','serial'],
    emits: ['update:title','update:serial'],
    data() {
      return {

      }
    },
    mounted() {
    },
    methods: {
      ...mapMutations([

      ]),
      removeHashtag() {
        if(this.serial.substring(0, 2) == "# ") {
          this.updateValue('serial', this.serial.substring(2, this.serial.length));
        }
      },
      addHashtag() {
        if(this.serial.substring(0, 2) != "# " && this.serial.length > 0) {
          this.updateValue('serial', "# " + this.serial);
        }
      },
      updateValue(prop,value) {
        this.$emit('update:'+prop,value);
      }
    },
    computed: {
      ...mapGetters([
      ]),
    }
  }
</script>
<template>
  <div
    class="fixed top-0 left-0 h-screen w-full z-20 py-8 overflow-y-scroll bg-il-softgrey"
    :class="currentRouteMX == 'clients/new-invoice' ? 'bg-il-dark' : 'bg-il-softgrey'"
  >
    <div
      @click="$parent.hidePreview"
      class="fixed top-0 right-0 bg-red-400 text-white p-4 font-bold uppercase rounded-bl-lg cursor-pointer"
    >
      Close
    </div>

    <div class="w-full flex justify-center">
      <div class="max-w-pdf w-full bg-white rounded-lg">
        <div class="flex flex-col p-4">

          <div class="flex gap-10">

            <div class="w-6/12 flex flex-col">
              <Logo
                :companyLogo="logo"
              />
              <From
                :name="fromName"
                :address="fromAddress"
              />
            </div>

            <div class="w-6/12 flex flex-col">
              <Title
                :title="title"
                :serial="serial"
              />

              <PaymentInfo
                :accentColor="accentColor"
                :accentTextColor="accentTextColor"
                :invoiceDateLabel="invoiceDateLabel"
                :invoiceDate="invoiceDate"
                :paymentDateLabel="paymentDateLabel"
                :paymentDate="paymentDate"
                :paymentTermsLabel="paymentTermsLabel"
                :paymentTerms="paymentTerms"
                :balanceDueLabel="balanceDueLabel"
                :formattedDueAmount="formattedDueAmount"
                :currency="currency"
                :currencyLabel="currencyLabel"
              />
            </div>

          </div>

          <div class="flex gap-20 mt-6">
            <To
              v-if="toLabel || toName || toAddress"
              :accentTextColor="accentTextColor"
              :toLabel="toLabel"
              :toName="toName"
              :toAddress="toAddress"
            />

            <ShipTo
              :accentTextColor="accentTextColor"
              :shipToLabel="shipToLabel"
              :shipToName="shipToName"
              :shipToAddress="shipToAddress"
            />
          </div>

          <div class="mt-10">
            <Items
              v-if="currency"
              :accentColor="accentColor"
              :currency="currency"
              :itemLabel="itemLabel"
              :quantityLabel="quantityLabel"
              :rateLabel="rateLabel"
              :amountLabel="amountLabel"
              :itemRows="itemRows"
            />
          </div>

          <div class="flex gap-5 mt-10">
            <div class="flex flex-col w-7/12" style="break-inside: avoid;">
              <NotesAndTerms
                :notesLabel="notesLabel"
                :notesText="notesText"
                :termsLabel="termsLabel"
                :termsText="termsText"
              />
            </div>

            <div class="flex flex-col w-5/12">
              <Totals
                :accentTextColor="accentTextColor"
                :currency="currency"
                :subtotal="subtotal"
                :accentColor="accentColor"
                :subtotalLabel="subtotalLabel"
                :discountLabel="discountLabel"
                :discount="discount"
                :discountType="discountType"
                :taxLabel="taxLabel"
                :tax="tax"
                :taxType="taxType"
                :shippingCostLabel="shippingCostLabel"
                :shippingCost="shippingCost"
                :amountPaidLabel="amountPaidLabel"
                :amountPaid="amountPaid"
                :totalLabel="totalLabel"
                :total="total"
                :discountCurrToPerc="discountCurrToPerc"
                :discountPercToCurr="discountPercToCurr"
                :taxCurrToPerc="taxCurrToPerc"
                :taxPercToCurr="taxPercToCurr"
              />
            </div>
          </div>

        </div>
      </div>
    </div>


  </div>
</template>

<script>
  import Logo from './Logo';
  import From from './From';
  import Title from './Title';
  import PaymentInfo from './PaymentInfo';
  import To from './To';
  import ShipTo from './ShipTo';
  import Items from './Items';
  import NotesAndTerms from './NotesAndTerms';
  import Totals from './Totals';

  export default {
    props: [
      'accentColor',
      'accentTextColor',
      'logo',
      'fromName',
      'fromAddress',
      'title',
      'serial',
      'invoiceDateLabel',
      'invoiceDate',
      'paymentDateLabel',
      'paymentDate',
      'paymentTermsLabel',
      'paymentTerms',
      'balanceDueLabel',
      'formattedDueAmount',
      'currency',
      'currencyLabel',
      'toLabel',
      'toName',
      'toAddress',
      'shipToLabel',
      'shipToName',
      'shipToAddress',
      'itemLabel',
      'quantityLabel',
      'rateLabel',
      'amountLabel',
      'itemRows',
      'notesLabel',
      'notesText',
      'termsLabel',
      'termsText',
      'subtotalLabel',
      'subtotal',
      'discountLabel',
      'discount',
      'discountType',
      'taxLabel',
      'tax',
      'taxType',
      'shippingCostLabel',
      'shippingCost',
      'amountPaidLabel',
      'amountPaid',
      'totalLabel',
      'total',
      'discountCurrToPerc',
      'discountPercToCurr',
      'taxCurrToPerc',
      'taxPercToCurr'
    ],
    data() {
      return {

      }
    },
    components: {
      Logo,
      From,
      Title,
      PaymentInfo,
      To,
      ShipTo,
      Items,
      NotesAndTerms,
      Totals
    },

  }
</script>
<template>
  <div class="bg-gray-50 p-4 mt-4 rounded-lg w-full max-w-pdf flex flex-col">

    <div class="flex flex-wrap md:justify-between">

      <div class="flex flex-wrap w-full md:w-auto order-2 md:order-1">
        <div
          v-if="!processingLoading && currentInvoice && canBeEdited"
          @click="$parent.deleteInvoice"
          class="w-full text-center md:w-auto mt-2 md:mt-0 uppercase text-sm px-4 py-2 rounded-lg border border-gray-300 font-medium bg-white text-il-dark hover:bg-il-dark hover:text-white cursor-pointer transition-all duration-200 ease-in-out"
        >
          <i class="fas fa-trash"></i>
          <span class="ml-2">Delete</span>
        </div>

        <div
          v-if="!processingLoading && canBeEdited"
          @click="$parent.resetForm"
          class="w-full text-center md:w-auto mt-2 md:mt-0 md:ml-2 uppercase text-sm px-4 py-2 rounded-lg border border-gray-300 font-medium bg-white text-il-dark hover:bg-il-dark hover:text-white cursor-pointer transition-all duration-200 ease-in-out"
        >
          <i class="fas fa-eraser"></i>
          <span class="ml-2">Reset</span>
        </div>
      </div>

      <div class="flex flex-wrap w-full md:w-auto order-1 md:order-2">
        <div
          v-if="!processingLoading"
          @click="$parent.showPreview"
          class="w-full text-center md:w-auto mt-2 md:mt-0 md:ml-2 uppercase text-sm px-4 py-2 rounded-lg border border-gray-300 font-medium bg-white text-il-dark hover:bg-il-dark hover:text-white cursor-pointer transition-all duration-200 ease-in-out"
        >
          <i class="fas fa-eye"></i>
          <span class="ml-2">Preview</span>
        </div>

        <div
          v-if="!processingLoading && canBeEdited"
          @click="$parent.createOrUpdate"
          class="w-full text-center md:w-auto mt-2 md:mt-0 md:ml-2 uppercase text-sm px-4 py-2 rounded-lg border border-gray-300 font-medium bg-white text-il-dark hover:bg-il-dark hover:text-white cursor-pointer transition-all duration-200 ease-in-out"
        >
          <i class="far fa-save"></i>
          <span class="ml-2" v-if="!currentInvoice">Save</span>
          <span class="ml-2" v-else>Update</span>
        </div>

        <div
          v-if="!processingLoading"
          @click="$parent.download"
          class="w-full text-center md:w-auto mt-2 md:mt-0 md:ml-2 uppercase text-sm px-4 py-2 rounded-lg border border-gray-300 font-medium transition-all duration-200 ease-in-out bg-white text-il-dark hover:bg-il-dark hover:text-white cursor-pointer"
        >
          <div>
            <i class="fas fa-cloud-download-alt"></i>
            <span class="ml-2">download</span>
          </div>
        </div>

        <div
          v-if="!processingLoading && canBeEdited"
          @click="$parent.showEmailInvoiceModal"
          class="w-full text-center md:w-auto mt-2 md:mt-0 md:ml-2 uppercase text-sm px-4 py-2 rounded-lg border border-gray-300 font-medium transition-all duration-200 ease-in-out bg-white text-il-dark hover:bg-il-dark hover:text-white cursor-pointer"
        >
          <div>
            <i class="fas fa-paper-plane"></i>
            <span class="ml-2">Email invoice</span>
          </div>
        </div>
      </div>

      <div
        v-if="processingLoading"
        class="w-full text-center md:w-auto mt-2 md:mt-0 ml-2 uppercase text-sm px-4 py-2 rounded-lg border border-gray-300 font-medium bg-il-dark text-white"
      >
        <div>
          <span class="mr-2">Processing</span>
          <i class="fas fa-circle-notch fa-spin"></i>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
  import { directive } from 'vue-tippy';

  export default {
    data() {
      return {
        updateClientDefaults: false
      }
    },
    directives: {
      'v-tippy': directive,
    },
    props: [
      'currentInvoice',
      'processingLoading'
    ],
    methods: {
    },
    computed: {
      canBeEdited() {
        if(!this.currentInvoice) {
          return true;
        }
        return !this.currentInvoice.sent_at && !this.currentInvoice.seen_at && !this.currentInvoice.paid_at;
      }
    }
  }
</script>


<template>
  <div class="flex flex-col items-center w-full m-8">
    <EmailInvoiceModal
      v-model:emailInvoiceModalVisible="emailInvoiceModalVisible"
      :client="client"
      :invoice="currentInvoice"
    />

    <InvoicePreview
      v-if="previewVisibile"
      :accentColor="accentColor"
      :accentTextColor="accentTextColor"
      :logo="logo"
      :fromName="fromName"
      :fromAddress="fromAddress"
      :title="title"
      :serial="serial"
      :invoiceDateLabel="invoiceDateLabel"
      :invoiceDate="invoiceDate"
      :paymentDateLabel="paymentDateLabel"
      :paymentDate="paymentDate"
      :paymentTermsLabel="paymentTermsLabel"
      :paymentTerms="paymentTerms"
      :balanceDueLabel="balanceDueLabel"
      :formattedDueAmount="formattedDueAmount"
      :currency="currency"
      :currencyLabel="currencyLabel"
      :toLabel="toLabel"
      :toName="toName"
      :toAddress="toAddress"
      :shipToLabel="shipToLabel"
      :shipToName="shipToName"
      :shipToAddress="shipToAddress"
      :itemLabel="itemLabel"
      :quantityLabel="quantityLabel"
      :rateLabel="rateLabel"
      :amountLabel="amountLabel"
      :itemRows="itemRows"
      :notesLabel="notesLabel"
      :notesText="notesText"
      :termsLabel="termsLabel"
      :termsText="termsText"
      :subtotal="subtotal"
      :subtotalLabel="subtotalLabel"
      :discountLabel="discountLabel"
      :discount="discount"
      :discountType="discountType"
      :taxLabel="taxLabel"
      :tax="tax"
      :taxType="taxType"
      :shippingLabel="shippingLabel"
      :shipping="shipping"
      :amountPaidLabel="amountPaidLabel"
      :amountPaid="amountPaid"
      :totalLabel="totalLabel"
      :total="total"
      :discountCurrToPerc="discountCurrToPerc"
      :discountPercToCurr="discountPercToCurr"
      :taxCurrToPerc="taxCurrToPerc"
      :taxPercToCurr="taxPercToCurr"
    />

    <Customisation
      v-if="currency"
      :clientName="client.name"
      v-model:accentColor="accentColor"
      v-model:accentTextColor="accentTextColor"
      v-model:currency="currency"
      :navigatedFromHistory="navigatedFromHistory"
      :clientUUID="client.uuid"
      @loadData="loadInvoiceData"
    />

    <div class="max-w-pdf w-full bg-white rounded-lg">
      <div class="flex flex-col p-4">

        <div class="flex flex-wrap">

          <div class="w-full md:w-6/12 flex flex-col">
            <Logo
              :companyLogo="logo"
            />
            <From
              :name="fromName"
              :address="fromAddress"
            />
          </div>

          <div class="w-full md:w-6/12 flex flex-col">
            <Title
              v-model:title="title"
              v-model:serial="serial"
            />

            <PaymentInfo
              v-if="currency"
              :accentColor="accentColor"
              :accentTextColor="accentTextColor"
              :currency="currency"
              :total="total"
              :paid="paid"
              :formattedDueAmount="formattedDueAmount"
              v-model:invoiceDateLabel="invoiceDateLabel"
              v-model:invoiceDate="invoiceDate"
              v-model:paymentDateLabel="paymentDateLabel"
              v-model:paymentDate="paymentDate"
              v-model:paymentTermsLabel="paymentTermsLabel"
              v-model:paymentTerms="paymentTerms"
              v-model:balanceDueLabel="balanceDueLabel"
              v-model:currencyLabel="currencyLabel"
            />
          </div>

        </div>

        <div class="flex flex-wrap">
          <To
            :accentTextColor="accentTextColor"
            v-model:toLabel="toLabel"
            v-model:toName="toName"
            v-model:toAddress="toAddress"
          />

          <ShipTo
            :accentTextColor="accentTextColor"
            v-model:shipToLabel="shipToLabel"
            v-model:shipToName="shipToName"
            v-model:shipToAddress="shipToAddress"
          />
        </div>

        <div class="mt-4 md:mt-10">
          <Items
            v-if="currency"
            :accentColor="accentColor"
            :rowValidation="rowValidation"
            :currency="currency"
            v-model:itemLabel="itemLabel"
            v-model:quantityLabel="quantityLabel"
            v-model:rateLabel="rateLabel"
            v-model:amountLabel="amountLabel"
            v-model:itemRows="itemRows"
          />
        </div>

        <div class="flex flex-wrap mt-10">
          <div class="flex flex-col w-full md:pr-4 md:w-7/12" style="break-inside: avoid;">
            <NotesAndTerms
              v-model:notesLabel="notesLabel"
              v-model:notesText="notesText"
              v-model:termsLabel="termsLabel"
              v-model:termsText="termsText"
            />
          </div>

          <div class="flex flex-col mt-4 md:mt-0 w-full md:pl-4 md:w-5/12">
            <Totals
              :accentTextColor="accentTextColor"
              :currency="currency"
              :subtotal="subtotal"
              :accentColor="accentColor"
              v-model:subtotalLabel="subtotalLabel"
              v-model:discountLabel="discountLabel"
              v-model:discount="discount"
              v-model:discountType="discountType"
              v-model:taxLabel="taxLabel"
              v-model:tax="tax"
              v-model:taxType="taxType"
              v-model:shippingLabel="shippingLabel"
              v-model:shipping="shipping"
              v-model:amountPaidLabel="amountPaidLabel"
              v-model:amountPaid="amountPaid"
              v-model:totalLabel="totalLabel"
              v-model:total="total"
            />
          </div>
        </div>

      </div>
    </div>

    <Actions
      :currentInvoice="currentInvoice"
      :processingLoading="processingLoading"
    />

  </div>
</template>

<script>
  import clientInvoiceRequests from '../../helpers/clientInvoiceRequests';

  import { mapGetters, mapMutations } from 'vuex';
  import InvoicePreview from '../../components/InvoicePreview/InvoicePreview';
  import Customisation from '../../components/ClientInvoice/Customisation';
  import Actions from '../../components/ClientInvoice/Actions';
  import Logo from '../../components/ClientInvoice/Logo';
  import From from '../../components/ClientInvoice/From';
  import Title from '../../components/ClientInvoice/Title';
  import PaymentInfo from '../../components/ClientInvoice/PaymentInfo';
  import To from '../../components/ClientInvoice/To';
  import ShipTo from '../../components/ClientInvoice/ShipTo';
  import Items from '../../components/ClientInvoice/Items';
  import NotesAndTerms from '../../components/ClientInvoice/NotesAndTerms';
  import Totals from '../../components/ClientInvoice/Totals';
  import EmailInvoiceModal from '../../components/ClientInvoice/EmailInvoiceModal';

  import lodash from 'lodash';
  import { add, format } from 'date-fns';

  export default {
    data() {
      return {
        emailInvoiceModalVisible: false,
        clientUUID: this.$route.params.client_uuid,
        invoiceUUID: this.$route.params.invoice_uuid,
        navigatedFromHistory: false,
        processingLoading: false,
        previewVisibile: false,
        logo: null,
        fromName: null,
        fromAddress: null,
        accentColor: null,
        accentTextColor: null,
        currency: null,
        title: null,
        serial: '# 1',
        invoiceDateLabel: 'invoice date',
        invoiceDate: format(new Date(), 'do MMM, yyyy'),
        paymentDateLabel: 'payment date',
        paymentDate: format(new Date(), 'do MMM, yyyy'),
        paymentTermsLabel: 'payment terms',
        paymentTerms: null,
        balanceDueLabel: 'balance due:',
        currencyLabel: 'currency',
        total: 0.00,
        paid: 0.00,
        toLabel: "Bill To:",
        toName: null,
        toAddress: null,
        shipToLabel: 'Ship To:',
        shipToName: null,
        shipToAddress: null,
        itemLabel: 'Item',
        quantityLabel: 'Quantity',
        rateLabel: 'Rate',
        amountLabel: 'Amount',
        itemRows: [
          {
            'item': 'New Item',
            'quantity': 1,
            'rate': 0.00,
            'isTime': false,
            'amount': 0.00,
            'perHourLabel': '/ hr'
          }
        ],
        notesLabel: 'notes',
        notesText: null,
        termsLabel: 'terms',
        termsText: null,
        subtotalLabel: 'subtotal',
        discountLabel: 'discount',
        discount: 0.00,
        discountType: '%',
        taxLabel: 'tax',
        tax: 0.00,
        taxType: '%',
        shippingLabel: 'shipping cost',
        shipping: 0.00,
        amountPaidLabel: 'amount paid',
        amountPaid: 0.00,
        totalLabel: 'total'
      }
    },
    mounted() {
      this.logo = this.getUserCurrentCompanyX.logo_url;
      this.fromName = this.getUserCurrentCompanyX.from_name;
      this.fromAddress = this.getUserCurrentCompanyX.from_address;

      if(this.invoiceUUID) {
        const invoiceUUID = this.invoiceUUID;
        let invoiceIndex = lodash.findIndex(this.client.client_invoices, function(i) {
          return i.uuid == invoiceUUID;
        });
        this.loadEditInvoiceData(this.client.client_invoices[invoiceIndex]);
        this.navigatedFromHistory = true;
      } else {
        this.loadInvoiceData(this.client);
      }
    },
    components: {
      InvoicePreview,
      Customisation,
      Actions,
      Logo,
      From,
      Title,
      PaymentInfo,
      To,
      ShipTo,
      Items,
      NotesAndTerms,
      Totals,
      EmailInvoiceModal
    },
    methods: {
      ...mapMutations([
        'addClientInvoiceX',
        'updateClientInvoiceX',
        'removeClientInvoiceX'
      ]),
      loadInvoiceData(client, invoiceData=null) {
        // this.invoiceUUID = invoiceData ? invoiceData.uuid : null;
        this.accentColor = client.accent_color;
        this.accentTextColor = client.accent_text_color;
        this.title = client.invoice_title;

        // let cloneFood = { ...food };
        this.invoiceDateLabel = client.invoice_date_label;
        this.invoiceDate = format(new Date(), 'do MMM, yyyy');

        // let cloneFood = { ...food };
        this.paymentDateLabel = client.payment_date_label;
        this.paymentDate = format(add(new Date(), {days: this.client.payment_period}), 'do MMM, yyyy');

        this.paymentTermsLabel = client.payment_terms_label;
        this.paymentTerms = client.payment_terms;
        this.balanceDueLabel = client.balance_due_label;

        const currency = lodash.find(this.getCurrenciesListX, function(c) { return c.id == client.currency_id; });
        this.currency = currency;

        this.currencyLabel = client.currency_label;
        this.toLabel = client.bill_to_label;
        this.toName = client.bill_to_name;
        this.toAddress = client.bill_to_address;
        this.shipToLabel = client.ship_to_label;
        this.shipToName = client.ship_to_name;
        this.shipToAddress = client.ship_to_address;
        this.itemLabel = client.item_label;
        this.quantityLabel = client.quantity_label;
        this.rateLabel = client.rate_label;
        this.amountLabel = client.amount_label;
        this.notesLabel = client.notes_label;
        this.notesText = client.notes;
        this.termsLabel = client.terms_label;
        this.termsText = client.terms;
        this.subtotalLabel = client.subtotal_label;
        this.discountLabel = client.discount_label;
        this.taxLabel = client.tax_label;
        this.shippingLabel = client.shipping_cost_label;
        this.amountPaidLabel = client.amount_paid_label;
        this.totalLabel = client.total_label;

        const allTextAreas = document.getElementsByTagName('textarea');
        let that = this;
        this.$nextTick(function() {
          lodash.forEach(allTextAreas, function(target) {
            const heightStep = target.getAttribute('data-height-step');
            that.autoResizeMX(target, heightStep);
          });
        });
      },
      loadEditInvoiceData(data) {
        this.invoiceUUID = data.uuid;
        this.accentColor = data.accent_color;
        this.accentTextColor = data.accent_text_color;
        this.logo = data.from_logo_url;
        this.fromName = data.from_name;
        this.fromAddress = data.from_address;
        this.title = data.invoice_title;
        this.serial = data.invoice_serial;
        this.invoiceDateLabel = data.invoice_date_label;
        this.invoiceDate = data.invoice_date;
        this.paymentDateLabel = data.payment_date_label;
        this.paymentDate = data.payment_date;
        this.paymentTermsLabel = data.payment_terms_label;
        this.paymentTerms = data.payment_terms;
        this.balanceDueLabel = data.balance_due_label;

        const currency = lodash.find(this.getCurrenciesListX, function(c) { return c.id == data.currency_id; });
        this.currency = currency;

        this.currencyLabel = data.currency_label;
        this.toLabel = data.bill_to_label;
        this.toName = data.bill_to_name;
        this.toAddress = data.bill_to_address;
        this.shipToLabel = data.ship_to_label;
        this.shipToName = data.ship_to_name;
        this.shipToAddress = data.ship_to_address;
        this.itemLabel = data.item_label;
        this.quantityLabel = data.quantity_label;
        this.rateLabel = data.rate_label;
        this.amountLabel = data.amount_label;
        this.itemRows = data.client_invoice_items;
        this.notesLabel = data.notes_label;
        this.notesText = data.notes;
        this.termsLabel = data.terms_label;
        this.termsText = data.terms;
        this.subtotalLabel = data.subtotal_label;
        this.discountLabel = data.discount_label;
        this.discount = data.discount;
        this.discountType = data.discount_type;
        this.taxLabel = data.tax_label;
        this.tax = data.tax;
        this.taxType = data.tax_type;
        this.shippingCostLabel = data.shipping_cost_label;
        this.shippingCost = data.shipping_cost;
        this.amountPaidLabel = data.amount_paid_label;
        this.amountPaid = data.amount_paid;
        this.totalLabel = data.total_label;
        this.total = data.total;

        const allTextAreas = document.getElementsByTagName('textarea');
        let that = this;
        this.$nextTick(function() {
          lodash.forEach(allTextAreas, function(target) {
            const heightStep = target.getAttribute('data-height-step');
            that.autoResizeMX(target, heightStep);
          });
        });
      },
      resetForm() {
        if(this.invoiceUUID) {
          const invoiceUUID = this.invoiceUUID;
          let invoiceIndex = lodash.findIndex(this.client.client_invoices, function(i) {
            return i.uuid == invoiceUUID;
          });
          this.loadEditInvoiceData(this.client.client_invoices[invoiceIndex]);
        } else {
          this.loadInvoiceData(this.client);
        }
      },
      showPreview() {
        this.allowBodyScrollMX(false);
        this.previewVisibile = true;
      },
      hidePreview() {
        this.allowBodyScrollMX(true);
        this.previewVisibile = false;
      },
      createOrUpdate() {
        if(!this.processingLoading) {
          this.processingLoading = true;
          const data = {
            "accent_color": this.accentColor,
            "accent_text_color": this.accentTextColor,
            "invoice_title": this.title,
            "invoice_serial": this.serial,
            "invoice_date_label": this.invoiceDateLabel,
            "invoice_date": this.invoiceDate,
            "payment_date_label": this.paymentDateLabel,
            "payment_date": this.paymentDate,
            "payment_terms_label": this.paymentTermsLabel,
            "payment_terms": this.paymentTerms,
            "balance_due_label": this.balanceDueLabel,
            "balance_due": this.formattedDueAmount,
            "currency_label": this.currencyLabel,
            "currency_id": this.currency.id,
            "bill_to_label": this.toLabel,
            "bill_to_name": this.toName,
            "bill_to_address": this.toAddress,
            "ship_to_label": this.shipToLabel,
            "ship_to_name": this.shipToName,
            "ship_to_address": this.shipToAddress,
            "item_label": this.itemLabel,
            "quantity_label": this.quantityLabel,
            "rate_label": this.rateLabel,
            "amount_label": this.amountLabel,
            "itemRows": this.itemRows,
            "notes_label": this.notesLabel,
            "notes": this.notesText,
            "terms_label": this.termsLabel,
            "terms": this.termsText,
            "subtotal_label": this.subtotalLabel,
            "subtotal": this.subtotal,
            "discount_label": this.discountLabel,
            "discount_type": this.discountType,
            "discount": this.discount,
            "tax_label": this.taxLabel,
            "tax_type": this.taxType,
            "tax": this.tax,
            "shipping_cost_label": this.shippingLabel,
            "shipping_cost": this.shipping,
            "amount_paid_label": this.amountPaidLabel,
            "amount_paid": this.amountPaid,
            "total_label": this.totalLabel,
            "total": this.total,
            "discount_curr_to_perc": this.discountCurrToPerc,
            "discount_perc_to_curr": this.discountPercToCurr,
            "tax_curr_to_perc": this.taxCurrToPerc,
            "tax_perc_to_curr": this.taxPercToCurr
          };

          clientInvoiceRequests.createOrUpdate(
            this.invoiceUUID,
            this.clientUUID,
            data
          )
          .then((response) => {
            if(this.invoiceUUID) {
              this.updateClientInvoiceX(response.data.invoice);
              this.popSuccessMessageMX(response.data.message);
            } else {
              this.invoiceUUID = response.data.uuid;
              this.addClientInvoiceX(response.data.invoice);
              this.popSuccessMessageMX(response.data.message);
            }
            this.processingLoading = false;
          }).catch(error => {
            this.processingLoading = false;
            console.log(error);
          });
        }
      },
      download() {
        if(!this.invoiceUUID) {
          this.popErrorMX("Please Save the invoice first.");
          return;
        }
        if(!this.processingLoading) {
          var that = this;
          this.processingLoading = true;
          clientInvoiceRequests.downloadPDF(
            this.invoiceUUID,
            this.getUserCurrentCompanyX.uuid,
            this.client.uuid
          )
          .then((downloadResponse) => {
            const url = window.URL.createObjectURL(new Blob([downloadResponse.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'invoice.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
            that.processingLoading = false;
            window.scrollTo(0,document.body.scrollHeight);
          }).catch(error => {
            that.processingLoading = false;
            // console.log(error);
          });
        }
      },
      deleteInvoice() {
        if(!this.processingLoading) {
          this.processingLoading = true;
          clientInvoiceRequests.deleteInvoice(
            this.invoiceUUID,
            this.getUserCurrentCompanyX.uuid,
            this.client.uuid
          )
          .then((response) => {
            this.processingLoading = false;
            this.popSuccessMessageMX(response.data.message);
            this.removeClientInvoiceX(this.currentInvoice.client_id, this.currentInvoice.id);
            if(!this.navigatedFromHistory) {
              this.navigateToMX('clients')
            } else {
              this.navigateToMX('clients/invoices-history', { client_uuid: this.clientUUID })
            }
            // this.loadInvoiceData(this.client);
          }).catch(error => {
            this.processingLoading = false;
            if(error.response.data.status == 'deleted') {
              this.$toast.error(error.response.data.message, {
                position: "bottom"
              });
            }
          });
        }
      },
      showEmailInvoiceModal() {
        if(!this.processingLoading) {
          this.processingLoading = true;
          const data = {
            "accent_color": this.accentColor,
            "accent_text_color": this.accentTextColor,
            "invoice_title": this.title,
            "invoice_serial": this.serial,
            "invoice_date_label": this.invoiceDateLabel,
            "invoice_date": this.invoiceDate,
            "payment_date_label": this.paymentDateLabel,
            "payment_date": this.paymentDate,
            "payment_terms_label": this.paymentTermsLabel,
            "payment_terms": this.paymentTerms,
            "balance_due_label": this.balanceDueLabel,
            "balance_due": this.formattedDueAmount,
            "currency_label": this.currencyLabel,
            "currency_id": this.currency.id,
            "bill_to_label": this.toLabel,
            "bill_to_name": this.toName,
            "bill_to_address": this.toAddress,
            "ship_to_label": this.shipToLabel,
            "ship_to_name": this.shipToName,
            "ship_to_address": this.shipToAddress,
            "item_label": this.itemLabel,
            "quantity_label": this.quantityLabel,
            "rate_label": this.rateLabel,
            "amount_label": this.amountLabel,
            "itemRows": this.itemRows,
            "notes_label": this.notesLabel,
            "notes": this.notesText,
            "terms_label": this.termsLabel,
            "terms": this.termsText,
            "subtotal_label": this.subtotalLabel,
            "subtotal": this.subtotal,
            "discount_label": this.discountLabel,
            "discount_type": this.discountType,
            "discount": this.discount,
            "tax_label": this.taxLabel,
            "tax_type": this.taxType,
            "tax": this.tax,
            "shipping_cost_label": this.shippingLabel,
            "shipping_cost": this.shipping,
            "amount_paid_label": this.amountPaidLabel,
            "amount_paid": this.amountPaid,
            "total_label": this.totalLabel,
            "total": this.total,
            "discount_curr_to_perc": this.discountCurrToPerc,
            "discount_perc_to_curr": this.discountPercToCurr,
            "tax_curr_to_perc": this.taxCurrToPerc,
            "tax_perc_to_curr": this.taxPercToCurr
          };

          clientInvoiceRequests.createOrUpdate(
            this.invoiceUUID,
            this.clientUUID,
            data
          )
          .then((response) => {
            if(this.invoiceUUID) {
              this.updateClientInvoiceX(response.data.invoice);
              this.popSuccessMessageMX(response.data.message);
            } else {
              this.invoiceUUID = response.data.uuid;
              this.addClientInvoiceX(response.data.invoice);
              this.popSuccessMessageMX(response.data.message);
            }
            this.processingLoading = false;
            this.emailInvoiceModalVisible = true;
          }).catch(error => {
            this.processingLoading = false;
            console.log(error);
          });
        }
      }
    },
    computed: {
      ...mapGetters([
        'getAccentColorsX',
        'getCurrenciesListX',
        'getUserCurrentCompanyX'
      ]),
      client() {
        var that = this;
        return lodash.find(this.getUserCurrentCompanyX.clients, function(c) {
          return c.uuid == that.clientUUID;
        });
      },
      currentInvoice() {
        if(this.invoiceUUID) {
          const invoiceUUID = this.invoiceUUID;
          let invoiceIndex = lodash.findIndex(this.client.client_invoices, function(i) {
            return i.uuid == invoiceUUID;
          });
          return this.client.client_invoices[invoiceIndex];
        }
        return null;
      },
      logoString() {
        if(this.logo) {
          return this.logo.substring(this.logo.indexOf(",") + 1);
        }
        return null;
      },
      logoExtension() {
        if(this.logo) {
          return this.logo.substring(this.logo.indexOf(":image/") + 7,this.logo.lastIndexOf(";"));
        }
        return null;
      },
      formattedDueAmount() {
        let due = parseFloat(this.total) - parseFloat(this.amountPaid);
        if(this.currency.after_nr) {
          return due.toFixed(2)  + " " + this.currency.symbol;
        }
        return this.currency.symbol + " " + due.toFixed(2);
      },
      rowValidation() {
        var rows = [];
        lodash.forEach(this.itemRows, function(row,index) {
          let quantityValid = true;
          if(row.isTime) { // Validate as hh:mm
            if(row.quantity.length < 3) {
              quantityValid = false;
            } else {
              // Format should be hh:mm
              const splitTime = row.quantity.split(":");
              const hrs = splitTime[0];
              const mins = splitTime[1];

              if(hrs < 0) {
                quantityValid = false;
              }
              if(mins > 59 || mins < 0) {
                quantityValid = false;
              }
            }
          } else { // Validate as Number
            quantityValid = !isNaN(row.quantity) ? true : false;
          }

          // empty strings evaluate to zero
          if(row.quantity === '') {
            quantityValid = false;
          }

          rows[index] = {
            "quantity": quantityValid,
            "rate": (row.rate !== '' && !isNaN(row.rate)) ? true : false,
          };
        });
        return rows;
      },
      subtotal() {
        let subtotal = 0;
        lodash.forEach(this.itemRows, function(row) {
          subtotal = parseFloat(subtotal) + parseFloat(row.amount);
        });
        return subtotal;
      },
      discountCurrToPerc() {
        return (this.discount*100) / this.subtotal;
      },
      discountPercToCurr() {
        return (this.discount * this.subtotal) / 100;
      },
      taxCurrToPerc() {
        if(this.discountType == '%') {
          return (this.tax*100) / (this.subtotal - this.discountPercToCurr);
        }
        return (this.tax*100) / (this.subtotal - this.discount);
      },
      taxPercToCurr() {
        if(this.discountType == '%') {
          return (this.tax * (this.subtotal - this.discountPercToCurr)) / 100;
        }
        return (this.tax * (this.subtotal - this.discount)) / 100;
      },
      compoundPropertyTotal: function() {
        return [this.subtotal, this.discount, this.tax, this.shipping, this.amountPaid];
      },
    },
    watch: {
      itemRows:function() { // Update Amounts
        var that = this;
        lodash.forEach(this.itemRows, function(row,index) {
          if(that.rowValidation[index].quantity && that.rowValidation[index].rate) {
            if(that.itemRows[index].isTime) {
              const hrs = that.itemRows[index].quantity.split(':')[0];
              const mins = that.itemRows[index].quantity.split(':')[1];
              const totalHours = parseInt(hrs) + (parseInt(mins)/60);

              that.itemRows[index].amount = (parseFloat(that.itemRows[index].rate) * parseFloat(totalHours)).toFixed(2);
            } else {
              that.itemRows[index].amount = (that.itemRows[index].quantity*that.itemRows[index].rate).toFixed(2);
            }
          } else {
            that.itemRows[index].amount = 0;
          }
        })
      },
      compoundPropertyTotal: function() {
        let discount = 0;
        if(this.discount !== '' && !isNaN(this.discount)) {
          if(this.discountType == '%') {
            discount = (this.discount * this.subtotal) / 100;
          } else {
            discount = this.discount;
          }
        }
        else {
          return "N/A";
        }

        let tax = 0;
        if(this.tax !== '' && !isNaN(this.tax)) {
          if(this.taxType == '%') {
            tax = (this.tax * (this.subtotal - discount)) / 100;
          } else {
            tax = this.tax;
          }
        }
        else {
          return "N/A";
        }

        let shipping = 0;
        if(this.shipping !== '' && !isNaN(this.shipping)) {
          shipping = parseFloat(this.shipping);
        }
        else {
          return "N/A";
        }

        let total = parseFloat(this.subtotal) - parseFloat(discount) + parseFloat(tax) + parseFloat(shipping); // - parseFloat(this.paid);
        this.total = parseFloat(total).toFixed(2);
      }
    },
  }
</script>
<template>

  <div class="bg-gray-50 p-2 mb-4 rounded-lg w-full max-w-pdf flex flex-col mt-8">
    <div
      v-if="!navigatedFromHistory"
      @click="navigateToMX('clients')"
      class="absolute flex items-center left-4 top-4 text-gray-400 hover:text-gray-100 duration-200 transition-all ease-in-out cursor-pointer"
    >
      <i class="fas fa-chevron-left fa-2x"></i>
      <span class="ml-4">Back to Clients</span>
    </div>
    <div
      v-else
      @click="navigateToMX('clients/invoices-history', { client_uuid: clientUUID })"
      class="absolute flex items-center left-4 top-4 text-gray-400 hover:text-gray-100 duration-200 transition-all ease-in-out cursor-pointer"
    >
      <i class="fas fa-chevron-left fa-2x"></i>
      <span class="ml-4">Back to Invoices History</span>
    </div>

    <div class="text-center mb-2 rounded-t-lg text-il-dark p-1">
      <div class="text-md">
        <span v-if="navigatedFromHistory">Editing Client invoice for</span>
        <span v-else>New client invoice for</span>
        <span class="ml-1 font-bold">{{ clientName }}</span>
      </div>
    </div>

    <div class="flex flex-wrap justify-around pb-2">

      <div class="flex flex-col w-full md:w-auto">
        <div class="text-il-dark text-xs uppercase text-center py-1">
          Accent Color
        </div>
        <div class="flex justify-center mb-4">
          <div
            v-for="(color,index) in getAccentColorsX"
            :key="index"
          >
            <div
              class="w-6 h-6 mx-1 rounded-lg relative cursor-pointer transition-all duration-200 ease-in-out"
              :class="accentColor == color ? 'shadow-none' : 'btn-shadow hover:shadow-none'"
              :style="{ backgroundColor: color }"
              @click="updateValue('accentColor',color)"
            >
              <div
                v-if="color == accentColor"
                class="absolute top-1 left-1.5 text-white text-xs"
                style=""
              >
                <i class="fas fa-check-circle"></i>
              </div>
            </div>
          </div>

          <div>
            <div
              class="w-6 h-6 mx-1 rounded-lg relative cursor-pointer transition-all duration-200 ease-in-out"
              :class="isCustomAccentColor ? '' : 'rainbow-bg btn-shadow'"
              :style="{ backgroundColor: accentColor }"
              @click="swatchShow()"
            >
              <div
                v-if="isCustomAccentColor"
                class="absolute top-0.5 right-0.5 text-xs border border-black rounded-full w-2 h-2 bg-white"
              ></div>
            </div>
            <input
              ref="colorPickerRef"
              class="invisible w-0 h-0 absolute"
              type="color"
              :value="accentColor"
              @input="pickColor($event.target, 'accentColor')"
            >
          </div>

        </div>
      </div>

      <div class="flex flex-col w-full md:w-auto">
        <div class="text-il-purple text-xs uppercase text-center py-1">
          Accent Text Color
        </div>
        <div class="flex justify-center mb-4">
          <div
            v-for="(color,index) in getAccentColorsX"
            :key="index"
            class=""
          >
            <div
              class="w-6 h-6 mx-1 rounded-lg relative cursor-pointer transition-all duration-200 ease-in-out"
              :class="accentTextColor == color ? 'shadow-none' : 'btn-shadow hover:shadow-none'"
              :style="{ backgroundColor: color }"
              @click="updateValue('accentTextColor',color)"
            >
              <div
                v-if="color == accentTextColor"
                class="absolute top-1 left-1.5 text-white text-xs"
                style=""
              >
                <i class="fas fa-check-circle"></i>
              </div>
            </div>
          </div>

          <div>
            <div
              class="w-6 h-6 mx-1 rounded-lg relative cursor-pointer transition-all duration-200 ease-in-out"
              :class="isCustomAccentTextColor ? '' : 'rainbow-bg btn-shadow'"
              :style="{ backgroundColor: accentTextColor }"
              @click="swatchTextShow()"
            >
              <div
                v-if="isCustomAccentTextColor"
                class="absolute top-0.5 right-0.5 text-xs border border-black rounded-full w-2 h-2 bg-white"
              ></div>
            </div>
            <input
              ref="colorPickerTextRef"
              class="invisible w-0 h-0 absolute"
              type="color"
              :value="accentTextColor"
              @input="pickColor($event.target, 'accentTextColor')"
            >
          </div>
        </div>
      </div>

      <div class="relative w-full md:w-auto">

        <div class="text-xs uppercase pl-px text-il-dark text-center">
          Currency
        </div>

        <div
          v-if="!listVisible"
          @click="toggleListVisibility"
          class="w-full md:w-300px p-1 text-il-dark border border-il-dark outline-none text-center rounded-lg bg-white cursor-pointer"
        >
          {{ currency.currency }}
        </div>
        <input
          v-else
          class="w-full md:w-300px p-1 text-il-dark border border-il-dark outline-none text-center rounded-t-lg uppercase"
          type="text"
          ref="searchQueryInput"
          v-model="searchQuery"
          placeholder="Search"
        />

        <div
          class="w-full md:w-300px  bg-white overflow-y-scroll overflow-x-hidden relative transition-all duration-200 ease-in-out border border-white rounded-b-lg"
          :class="listVisible ? 'h-150px border-il-dark' : 'h-0'"
        >
          <div
            class="border-b border-il-dark flex items-center p-1 hover:bg-il-dark-faded transition-all duration-200 ease-in-out cursor-pointer"
            @click="chooseCurrency(currency)"
            v-for="currency in filteredCurrencies"
            :key="currency.id"
          >
            <div class="bg-il-dark w-14 border p-2 text-center text-white">
              {{ currency.symbol }}
            </div>
            <div class="flex flex-col pl-2 text-black">
              <div class="text-sm">
                {{ currency.currency }}
              </div>
              <div class="text-xs text-gray-500">
                {{ currency.country }}
              </div>
            </div>
          </div>
        </div>

        <div
          @click="toggleListVisibility"
          class="absolute p-1 right-1 top-4 text-il-dark cursor-pointer"
        >
          <span v-if="listVisible">
            <i class="fas fa-times-circle"></i>
          </span>
          <span v-else>
            <i class="fas fa-chevron-circle-down"></i>
          </span>

        </div>
      </div>

    </div>

  </div>

</template>

<script>
  import quickInvoiceRequests from '../../helpers/quickInvoiceRequests';
  import { mapGetters, mapMutations } from 'vuex';
  import lodash from 'lodash';

  export default {
    props: ['clientUUID','clientName','accentColor','accentTextColor','currency','navigatedFromHistory'],
    emits: ['update:accentColor','update:accentTextColor','update:currency','loadData'],
    data() {
      return {
        listVisible: false,
        searchQuery: '',
        invoiceDataLoading: false,
        invoiceUUID: null
      }
    },
    mounted() {

    },
    components: {
    },
    methods: {
      ...mapMutations([

      ]),
      loadInvoiceData() {
        if(!this.invoiceDataLoading) {
          this.invoiceDataLoading = true;
          quickInvoiceRequests.getInvoiceDetails(this.invoiceUUID)
          .then((response) => {
            this.$emit('loadData', response.data.invoice_details);
            this.invoiceDataLoading = false;
            this.$toast.success(response.data.message, {
              position: "bottom"
            });
          }).catch(error => {
            if(error.response.data.status == 'deleted') {
              this.$toast.error(error.response.data.message, {
                position: "bottom"
              });
            }
            this.invoiceDataLoading = false;
          });
        }
      },
      toggleListVisibility() {
        this.listVisible = !this.listVisible;
        this.$nextTick(function() {
          if(this.listVisible) {
            this.$refs.searchQueryInput.focus();
          }
        });
      },
      chooseCurrency(currency) {
        this.updateValue('currency',currency);
        this.toggleListVisibility();
        this.searchQuery = '';
      },
      updateValue(prop,value) {
        this.$emit('update:'+prop, value);
      },
      swatchShow() {
        this.$refs.colorPickerRef.click();
      },
      swatchTextShow() {
        this.$refs.colorPickerTextRef.click();
      },
      pickColor(event, prop) {
        this.$emit('update:'+prop, event.value);
      }
    },
    computed: {
      ...mapGetters([
        'getAccentColorsX',
        'getCurrenciesListX'
      ]),
      filteredCurrencies() {
        var query = this.searchQuery;

        if(query == '') {
          return this.getCurrenciesListX;
        }
        return lodash.filter(this.getCurrenciesListX, function(c) {
          return (c.currency.toLowerCase().includes(query.toLowerCase())  || c.country.toLowerCase().includes(query.toLowerCase()) || c.code.toLowerCase().includes(query.toLowerCase()));
        });
      },
      isCustomAccentColor() {
        return !this.getAccentColorsX.includes(this.accentColor);
      },
      isCustomAccentTextColor() {
        return !this.getAccentColorsX.includes(this.accentTextColor);
      }
    }
  }
</script>

<style scoped>
  .rainbow-bg {
    background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);
  }
</style>